import request from '@/utils/request'
import { getOSToken } from '@/utils/auth';

var stream = require("stream");
var downloadJS = require('downloadjs');

const FormData = require('form-data');
const fs = require('fs');
const qs = require('querystring');

//import request from '@/utils/request'
import setting from '@/settings.js'

const axios = require('axios');

function IsIOS() {
    let OS = getOSToken();

    if (OS && OS.toLowerCase() === 'ios') {
        return true;
    }

    return false;
}

function GetLeaderBoardURL() {
    if (IsIOS()) {
        return setting.leader_board_ios;
    }

    return setting.leader_board;
}

function GetLeaderBoardHelperURL() {
    if (IsIOS()) {
        return setting.leader_board_helper_ios;
    }

    return setting.leader_board_helper;
}

function GetLeaderBoardHelperBlackListURL() {
    if (IsIOS()) {
        return setting.leader_board_helper_blacklist_ios;
    }

    return setting.leader_board_helper_blacklist;
}

function GetUploadCDNURL() {
    if (IsIOS()) {
        return setting.upload_file_CDN_ios;
    }

    return setting.upload_file_CDN;
}

function GetDownloadCDNURL() {
    if (IsIOS()) {
        return setting.download_file_CDN_ios;
    }

    return setting.download_file_CDN;
}

export function GetDownloadCDN_URL() {
    return GetDownloadCDNURL();
}

export function getCheatTime() {
    //let baseURL = setting.leader_board;
    let baseURL = GetLeaderBoardURL();
    let url = baseURL + '/lb_getcheattime/';

    return axios.get(url);
}

export function setCheatTime(cheatTimeOffset) {
    //let baseURL = setting.leader_board;
    let baseURL = GetLeaderBoardURL();
    let url = baseURL + '/lb_setcheattime/?cheatTimeOffset=' + cheatTimeOffset;

    return axios.get(url);
}

export function getBlackList(selectedApplicationID) {
    //let baseURL = setting.leader_board_helper_blacklist;
    let baseURL = GetLeaderBoardHelperBlackListURL();
    let url = baseURL + '/lb_blacklist/?app=' + selectedApplicationID;

    return axios.get(url);
}

export function setBlackListUser(selectedApplicationID, userID, description, opt) {
    //let baseURL = setting.leader_board_helper_blacklist;
    let baseURL = GetLeaderBoardHelperBlackListURL();
    let url = baseURL + '/lb_setblacklist/?app=' + selectedApplicationID + '&userID=' + userID + "&description=" + description + "&opt=" + opt;

    return axios.get(url);
}

export function getApplicationInfos() {
    //let baseURL = setting.leader_board;
    //let url = baseURL + '/lb_getappinfo';

    //return axios.get(url);

    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = { actionName: 'get_application_info' };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function UploadFileToRemoteCDN(fileBlob, fileName, filePath) {
    //let baseURL = setting.upload_file_CDN;
    let baseURL = GetUploadCDNURL();

    let url = baseURL + '/uploadfilecdn' + '?pathName=' + encodeURIComponent(filePath);

    let formData = new FormData();

    formData.append('pathName', 'upcdn');
    formData.append('upload_file', fileBlob, fileName);

    return axios.post(url, formData, {
        // need to use getHeaders because Axios doesn't
        // automatically set the multipart form.
        headers: formData.getHeaders
    });
}

export function GetUploadFileList(filePath) {
    //let baseURL = setting.upload_file_CDN;
    let baseURL = GetUploadCDNURL();

    let url = baseURL + '/getuploadfile' + '?pathName=' + encodeURIComponent(filePath);

    let formData = new FormData();

    formData.append('pathName', 'upcdn');

    return axios.post(url, formData, {
        // need to use getHeaders because Axios doesn't
        // automatically set the multipart form.
        headers: formData.getHeaders
    });
}

export function UploadFileStatus(option) {
    //let baseURL = setting.upload_file_CDN;
    let baseURL = GetUploadCDNURL();
    let url = baseURL + '/status' + '?opt=' + encodeURIComponent(option);

    return axios.get(url);
}

export function UploadFilePollingStatus(option) {
    //let baseURL = setting.upload_file_CDN;
    let baseURL = GetUploadCDNURL();
    let url = baseURL + '/status' + '?opt=' + encodeURIComponent(option);

    return axios.get(url);
}

export function HelperGetDownloadLinks(selectedApplicationID) {
    //let baseURL = setting.leader_board_helper_download;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/getdownloadlinks?app=' + selectedApplicationID;

    return axios.get(url);
}

export function HelperRestoreDataFromBackup(selectedApplicationID, backupKey) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'restore_from_backup',
        appID: selectedApplicationID,
        backupKey: backupKey
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetEventData(selectedApplicationID) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_event_data',
        appID: selectedApplicationID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetEventDataList() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_event_data_list'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperSetEventData(selectedApplicationID, eventData) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'set_event_data',
        appID: selectedApplicationID,
        eventData: JSON.stringify(eventData)
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetLeaderBoardAllSettings() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_all_settings'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperLeaderboard_GetCheatLogFileList(bossName) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_leaderboard_cheat_log_file_list',
        bossName: bossName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetRaidBossAllSettings() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_all_raidboss_settings'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperResetAllRaidBossData(name, leaderboardGrand, leaderboardLittle) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'reset_all_raidboss_data',
        name: name,
        leaderboardGrand: leaderboardGrand,
        leaderboardLittle: leaderboardLittle
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRaidBoss_ResetBossGroup(bossName, groupName, hp) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'reset_boss_group',
        bossName: bossName,
        groupName: groupName,
        hp: hp
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRaidBoss_GetStatusBossGroup(bossName, groupName) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_info_boss_group',
        bossName: bossName,
        groupName: groupName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRaidBoss_GetStatusAllBossGroup(bossName) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_info_all_boss_group',
        bossName: bossName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRaidBoss_GetDailyStatisticFileList(bossName) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_statistic_daily_file_list',
        bossName: bossName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRaidBoss_GetCheatLogFileList(bossName) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_raidboss_cheat_log_file_list',
        bossName: bossName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperLeaderBoard_GetOldBackupFileList() {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_leaderboard_old_backup_list'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperLeaderBoard_GetCurrentErrorLogFileList() {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_leaderboard_current_error_log_list'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperLeaderBoard_GetLastRewardLogFileList(appID) {

    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_leaderboard_last_reward_log_list',
        appID: appID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetStatusPolling() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'status_polling'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetAllLeaderBoardInfo(selectedApplicationID) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_all_leaderboard_info',
        appID: selectedApplicationID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperSetLeaderBoardAllSettings(settings) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';
    let OS = getOSToken();

    let bodyData = {
        actionName: 'set_all_settings',
        settings: JSON.stringify(settings),
        OS: OS
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperSetLeaderBoardAddMultiSettings(settings) {
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';
    let OS = getOSToken();

    let bodyData = {
        actionName: 'add_multi_settings',
        settings: JSON.stringify(settings),
        OS: OS
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperSetLeaderBoardRemoveMultiSettings(settings) {
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';
    let OS = getOSToken();

    let bodyData = {
        actionName: 'remove_multi_settings',
        settings: JSON.stringify(settings),
        OS: OS
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperSetRaidBossAllSettings(settings) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';
    let OS = getOSToken();

    let bodyData = {
        actionName: 'set_raidboss_settings',
        settings: JSON.stringify(settings),
        OS: OS
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperApplyLeaderBoardAllSettings() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'apply_all_settings'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperApplyLeaderBoardAllSettingsPolling() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'apply_all_settings_polling'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperApplyRaidBossAllSettingsPolling() {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'apply_all_raidboss_polling'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperTryAddTestUsers(selectedApplicationID, dataUsers) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_try_add_users',
        users: dataUsers,
        appID: selectedApplicationID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperTryAddTestUserScores(selectedApplicationID, dataUserScores) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_try_add_user_scores',
        userscores: dataUserScores,
        appID: selectedApplicationID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

//---------------------------------------------------
// Testing List
export function HelperAddTestingUser(userID, description) {
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_add_testing_user',
        id: userID,
        description: description
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRemoveTestingUser(userID) {
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_remove_testing_user',
        id: userID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetTestingUserList() {
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_get_testing_list'
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}
//---------------------------------------------------

export function TryParseUploadFile_ReadTextFile(file, callback) {
    const reader = new FileReader();

    reader.addEventListener("load", function () {
        callback(0, reader.result);
    }, false);

    reader.readAsText(file);
}

// input_files: [{name: ,dataRaw: }, ...]
// output: [{name: ,dataText: }, ...]
export function ReadTextFile(input_files, callback) {

    const readFileFunc = async (name, dataRaw) => {

        let result_text = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = (e) => resolve(reader.result);
            reader.readAsText(dataRaw);
        });

        return { name: name, dataText: result_text };
    };

    let promises = [];

    for (let i = 0; i < input_files.length; i++) {
        promises.push(readFileFunc(input_files[i].name, input_files[i].dataRaw));
    }

    Promise.all(promises).then((response) => {
        let list_out = response;

        // sort list out by name
        // ...
        let output_result = [];
        for (let i = 0; i < input_files.length; i++) {
            for (let j = 0; j < list_out.length; j++) {
                if (input_files[i].name === list_out[j].name) {
                    output_result.push({ name: input_files[i].name, dataText: list_out[j].dataText });
                }
            }
        }

        callback(0, output_result);
    });
}

export function HelperParseRewardFromFile(fileContent) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'parse_rewards_from_file',
        name: "",
        fileContent: fileContent
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperParseRewardFromFileWithName(name, fileContent) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'parse_rewards_from_file',
        name: name,
        fileContent: fileContent
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperParseJsonFromConfigFile(fileContent) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'parse_json_from_config_file',
        fileContent: fileContent
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperApplyEventTime(eventID, startTime, endTime) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'apply_event_time',
        eventID: eventID,
        startTime: startTime,
        endTime: endTime
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetTopUser(selectedApplicationID, groupName) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_top_user',
        appID: selectedApplicationID,
        group_name: groupName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperGetUserData(SelectedApplicationID, UserID) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'get_user_data',
        appID: SelectedApplicationID,
        userID: UserID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperChangeUserGroup(SelectedApplicationID, UserID, groupName) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'move_user_to_group',
        appID: SelectedApplicationID,
        userID: UserID,
        group_name: groupName
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function HelperRestoreCheatUserGroupToNomalGroup(SelectedApplicationID, UserID) {
    //let baseURL = setting.leader_board_helper;
    let baseURL = GetLeaderBoardHelperURL();
    let url = baseURL + '/actions';

    let bodyData = {
        actionName: 'lb_restore_user_from_cheat_group',
        appID: SelectedApplicationID,
        userID: UserID
    };

    return axios.post(url, qs.stringify(bodyData), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
}

export function DownloadJS(data, name, contentType) {
    downloadJS(data, name, contentType);
}